import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../services/api.service';
import { CommonService } from '../services/common.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-new-address',
  templateUrl: './add-new-address.page.html',
  styleUrls: ['./add-new-address.page.scss'],
})
export class AddNewAddressPage implements OnInit {
  @Input() editAddr: string;
  country: [{ name: 'Malaysia' }];

  state = [
    {
      name: 'Selangor',
    },
    {
      name: 'Kuala Lumpur',
    },
    {
      name: 'Johor',
    },
    {
      name: 'Kedah',
    },
    {
      name: 'Kelantan',
    },
    {
      name: 'Melacca',
    },
    {
      name: 'Negeri Sembilan',
    },
    {
      name: 'Pahang',
    },
    {
      name: 'Penang',
    },
    {
      name: 'Perak',
    },
    {
      name: 'Perlis',
    },
    {
      name: 'Putrajaya',
    },
    {
      name: 'Terengganu',
    },
  ];

  formData = {
    fullName: '',
    address1: '',
    address2: '',
    postalCode: '',
    city: '',
    state: '',
    country: 'Malaysia',
    phoneNumber: '',
    latitude: '0',
    longitude: '0',
  };
  userInfo;
  position;
  positionDisp;
  isEdit = false;
  constructor(
    private api: ApiService,
    private common: CommonService,
    private translate: TranslateService,
    private router: Router,
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.common.getUserInfo('user').then((userInfo) => {
      this.userInfo = userInfo;
    });

    if (this.editAddr) {
      this.isEdit = true;
      this.formData = JSON.parse(this.editAddr);
    }
    if (this.formData['state'] === '') {
      this.formData['state'] = this.state[0]['name'];
    }
  }

  assignFormData(column, value) {
    // this.formData[column] =
    //   this.formData[column] === "" ? value : this.formData[column];
    this.formData[column] = value;
  }

  verifyAddress() {
    let msg = '';
    if (this.formData.fullName === '') {
      msg += this.translate.instant('Fullname') + ',';
    }
    if (this.formData.phoneNumber === '') {
      msg += this.translate.instant('Phone Number') + ',';
    }
    if (this.formData.address1 === '') {
      msg += this.translate.instant('Address 1') + ',';
    }
    if (this.formData.postalCode === '') {
      msg += this.translate.instant('Postal Code') + ',';
    }
    if (this.formData.city === '') {
      msg += this.translate.instant('City') + ',';
    }
    if (this.formData.state === '') {
      msg += this.translate.instant('State') + ', ';
    }
    if (this.formData.country === '') {
      msg += this.translate.instant('Country') + ', ';
    }
    // if (this.formData.latitude === "" || this.formData.longitude === "") {
    //   msg += "Location, ";
    // }
    if (msg !== '') {
      msg = msg.substring(0, msg.length - 2);

      this.common.presentToast({
        message: msg + ' ' + this.translate.instant('cannot be empty.'),
      });
      return false;
    }
    return true;
  }

  async presentAlert(errMsg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '',
      message: errMsg,
      buttons: [
        {
          text: this.translate.instant('OK'),
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  saveBtnClicked() {
    if (this.isEdit) {
      this.updateAddress();
    } else {
      this.addNewAddress();
    }
  }

  addNewAddress() {
    if (this.verifyAddress()) {
      let body = { formData: this.formData, userInfo: this.userInfo };
      this.api.addAddress(body).subscribe(
        (res) => {
          this.common
            .presentToast({
              message: this.translate.instant('Address added successful.'),
            })
            .then(() => {
              this.modalController.dismiss({
                isEdit: this.isEdit,
                addedSuccess: true,
                updatedSuccess: false,
                cancel: false,
              });
            });
        },
        (err) => {
          this.presentAlert(err.message);
        }
      );
    }
  }
  updateAddress() {
    if (this.verifyAddress()) {
      let dataBody = _.cloneDeep(this.formData);
      let dataId = dataBody['id'];
      delete dataBody['id'];

      let body = {
        formData: dataBody,
        userInfo: this.userInfo,
        id: dataId,
      };
      this.api.updateAddress(body).subscribe(
        (res) => {
          this.common
            .presentToast({
              message: this.translate.instant('Address updated successful.'),
            })
            .then(() => {
              this.modalController.dismiss({
                isEdit: this.isEdit,
                addedSuccess: false,
                updatedSuccess: true,
                cancel: false,
              });
            });
        },
        (err) => {
          this.presentAlert(err.message);
        }
      );
    }
  }

  cancel() {
    this.modalController.dismiss({
      isEdit: this.isEdit,
      addedSuccess: false,
      updatedSuccess: false,
      cancel: true,
    });
  }
}
