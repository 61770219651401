import { NgModule, Directive, ElementRef } from "@angular/core";
@Directive({
  selector: "[appScrollbarTheme]",
})
export class ScrollbarThemeDirective {
  constructor(el: ElementRef) {
    const stylesheet = `
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: lightgrey;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: darkgrey;
      }
    `;

    const styleElmt = el.nativeElement.shadowRoot.querySelector("style");
    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement("style");
      barStyle.append(stylesheet);
      el.nativeElement.shadowRoot.appendChild(barStyle);
    }
  }
}

@NgModule({
  declarations: [ScrollbarThemeDirective],
  exports: [ScrollbarThemeDirective],
})
export class ScrollbarThemeModule {}
