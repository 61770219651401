import { Injectable } from '@angular/core';
import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { AlertController, ToastController } from '@ionic/angular';
import * as _ from 'lodash';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private toastCtrl: ToastController,
    private uniqueDeviceID: UniqueDeviceID,
    private spinner: SpinnerDialog,
    private platform: Platform,
    private _storage: Storage,
    private alertCtrl: AlertController,
    private translate: TranslateService
  ) {
    // console.log("common service constructor");
  }
  public userinfo;
  public isApp;
  isApps() {
    if (this.platform.is('mobileweb')) {
      this.isApp = false;
    } else {
      this.isApp = true;
    }
    this.isApp = false;
  }

  async presentToast(obj) {
    const toast = await this.toastCtrl.create({
      message: obj['message'],
      duration: obj['duration'] || 3000,
      position: obj['position'] || 'bottom',
      color: obj['color'] || 'primary',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
            // console.log("Cancel clicked");
            toast.dismiss();
          },
        },
      ],
    });
    toast.present();
  }
  async presentToastWithOptions(err) {
    const toast = await this.toastCtrl.create({
      header: err['code'],
      message: err['message'],
      position: err['position'] || 'middle',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
            // console.log("Cancel clicked");
            toast.dismiss();
          },
        },
      ],
    });
    toast.present();
  }

  async presentErrorToast(obj) {
    const toast = await this.toastCtrl.create({
      message: obj['errorMessage'],
      duration: 1500,
    });
    toast.present();
  }

  async presentAlert(obj) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class-alert',
      header: obj['header'] || '',
      message: obj['message'],
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          },
        },
      ],
    });
    alert.present();
  }

  async getDeviceInfo() {
    // this.isApps();

    // let deviceId = await this._storage.get("deviceId");
    let deviceId = await localStorage.getItem('deviceId');

    if (_.isNull(deviceId)) {
      // if (this.isApp) {
      // deviceId = await this.uniqueDeviceID.get();
      // } else {
      deviceId = this.uuidv4();

      // await this._storage.set("deviceId", deviceId);
      await localStorage.setItem('deviceId', deviceId);
    }

    deviceId = _.isUndefined(deviceId) ? this.uuidv4() : deviceId;

    // console.log("deviceId:" + deviceId);

    return {
      deviceId: deviceId,
      deviceType: 1,
      pushNotificationData: '',
      guiVersion: '1.0.0',
    };
  }
  async showSpinner() {
    this.spinner.show(null, null, true);
  }
  async hideSpinner() {}
  async getUserInfo(name) {
    return this.getStorage(name);
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  getStorage(key) {
    return new Promise((resolve, reject) => {
      this._storage.get(key).then((res) => {
        resolve(res);
      });
    });
  }

  calculatePerc(data) {
    let result = 0;
    result =
      ((data.orignalPrice - data.discountedPrice) / data?.orignalPrice) * 100;

    return result.toFixed(0);
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getOrderStatus(val) {
    if (val === 0) {
      return this.translate.instant('Pending Payment');
    }
    if (val === 1) {
      return this.translate.instant('Cancelled');
    }
    if (val === 2) {
      return this.translate.instant('Payment Success');
    }
    if (val === 3) {
      return this.translate.instant('Payment Failed');
    }
    if (val === 4) {
      return this.translate.instant('Agent Assigned');
    }
    if (val === 5) {
      return this.translate.instant('Preparing');
    }
    if (val === 6) {
      return this.translate.instant('Out For Delivery');
    }
    if (val === 7) {
      return this.translate.instant('Delivered');
    }

    // PENDING_PAYMENT = 0,
    // CANCELLED = 1,
    // PAYMENT_SUCCESS = 2,
    // PAYMENT_FAILED = 3,
    // AGENT_ASSIGNED = 4,
    // PREPARING = 5,
    // OUT_FOR_DELIVERY = 6,
    // DELIVERED = 7
  }
  getWithdrawalStatus(val) {
    if (val === 0) {
      return this.translate.instant('Submitted');
    }
    if (val === 1) {
      return this.translate.instant('Processing');
    }
    if (val === 2) {
      return this.translate.instant('Successful');
    }
    if (val === 3) {
      return this.translate.instant('Rejected');
    }

    // SUBMITTED = 0
    // PROCESSING = 1
    // SUCCESSFUL = 2
    // REJECTED = 3
  }

  getBaseUrl() {
    return environment.api_url;
  }

  validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  }

  shuffle(array) {
    var currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
}
