import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-download-modal",
  templateUrl: "./download-modal.component.html",
  styleUrls: ["./download-modal.component.scss"],
})
export class DownloadModalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
  goToLink(url) {
    window.open(url, "_blank");
  }
}
