import { Component, OnInit } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateConfigService } from './translate-config.service';
import { Storage } from '@ionic/storage-angular';
import { AuthService } from './services/auth.service';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UrlService } from './services/url.service';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  currentRoute: any;
  previousUrl: string = null;
  currentUrl: string = null;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateConfigService: TranslateConfigService,
    private menu: MenuController,
    private _storage: Storage,
    private router: Router,
    private urlApi: UrlService
  ) {
    this._storage.defineDriver(CordovaSQLiteDriver);
    this._storage.create();
  }
  async ngOnInit() {
    await this.initializeApp();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.urlApi.setPreviousUrl(this.previousUrl);
      });
  }
  systemLanguage: any;
  async initializeApp() {
    await this.platform.ready();
    this.statusBar.styleDefault();
    this.statusBar.backgroundColorByHexString('#FFFFFF'); //#8E54E9
    this.splashScreen.hide();

    await this.updateAppLanguage();

    // this.auth.refreshToken().subscribe();
  }

  async updateAppLanguage() {
    let lang = await this._storage.get('appLanguage');

    if (!lang) {
      this._storage.set('appLanguage', 'en-US');
      this.systemLanguage = 'en-US';
    } else {
      this.systemLanguage = lang;
    }
    this.translateConfigService.setLanguage(this.systemLanguage);
  }

  closeMenu() {
    this.menu.close('first');
  }
}
