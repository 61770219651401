import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { ActionSheetController, ModalController } from "@ionic/angular";
import { AddNewAddressPage } from "src/app/add-new-address/add-new-address.page";
import { ApiService } from "src/app/services/api.service";
import { CommonService } from "src/app/services/common.service";
import * as _ from "lodash";
@Component({
  selector: "app-address-card",
  templateUrl: "./address-card.component.html",
  styleUrls: ["./address-card.component.scss"],
})
export class AddressCardComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    public actionSheetController: ActionSheetController,
    private api: ApiService,
    private common: CommonService,
    private router: Router,
    private modalController: ModalController
  ) {}
  @Input() gotChanges: string;
  @Output() gotChangesChange = new EventEmitter();

  @Input() isSelect: boolean = false;
  @Input() selectedAddress: string = "";
  addresses = [];
  userInfo;
  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    this.ngOnInit();
  }

  itemChecked(item) {
    for (let i = 0; i <= this.addresses.length - 1; i++) {
      this.addresses[i]["isChecked"] = false;
    }

    item.isChecked = !item.isChecked;

    if (item.isChecked) {
      this.selectedAddress = item;
    }
  }

  getAddress() {
    let body = { userInfo: this.userInfo };
    this.api.getAddress(body).subscribe(
      (res) => {
        this.addresses = res;

        if (this.selectedAddress !== "") {
          let fndIdx = _.findIndex(this.addresses, (ele) => {
            return Number(ele.id) === Number(this.selectedAddress["id"]);
          });

          if (fndIdx !== -1) {
            this.addresses[fndIdx]["isChecked"] = true;
          }
        }
      },
      (err) => {
        this.common.presentToast(err.message);
      }
    );
  }
  async makePrimary(addr) {
    const actionSheet = await this.actionSheetController.create({
      header: "Choose your primary address",
      buttons: [
        {
          text: "Make this as default",
          handler: () => {
            this.updatePrimaryAddr(addr.id);
          },
        },
        {
          text: "Edit",
          icon: "create",
          handler: () => {
            this.goToEdit(addr);
          },
        },
        {
          text: "Delete",
          icon: "trash",
          handler: () => {
            this.deleteAddress(addr.id);
          },
        },
        {
          text: "Cancel",
          icon: "close",
          role: "cancel",
          handler: () => {
            // console.log("Cancel clicked");
          },
        },
      ],
    });
    await actionSheet.present();
  }

  ngOnInit() {
    this.common.getUserInfo("user").then((userInfo) => {
      this.userInfo = userInfo;
      this.getAddress();
      this.gotChangesChange.emit("");
    });
  }

  ngOnDestroy() {
    // console.log("onDestroy");
  }

  updatePrimaryAddr(id_) {
    let body = { userInfo: this.userInfo, id: id_ };

    this.api.updateAddressPrimary(body).subscribe(
      (res) => {
        this.getAddress();
      },
      (err) => {
        this.common.presentToast(err.message);
      }
    );
  }

  deleteAddress(id_) {
    let body = { userInfo: this.userInfo, id: id_ };

    this.api.deleteAddress(body).subscribe(
      (res) => {
        this.getAddress();
      },
      (err) => {
        this.common.presentToast(err.message);
      }
    );
  }

  back() {
    // this.router.navigate(["/account"], { replaceUrl: true });
    this.router.navigate(["/account"]);
  }

  async goToEdit(addr?) {
    const modal = await this.modalController.create({
      component: AddNewAddressPage,
      cssClass: "my-custom-modal-css",
      componentProps: {
        editAddr: JSON.stringify(addr),
      },
    });
    await modal.present();
    const data = await modal.onDidDismiss();
    if (data?.data?.updatedSuccess || data?.data?.addedSuccess) {
      this.ngOnInit();
    }
  }

  cancel() {
    this.modalController.dismiss();
  }
  save() {
    this.modalController.dismiss({ address: this.selectedAddress });
  }
}
