import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';

import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import * as _ from 'lodash';
import { NavigationExtras, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart-card',
  templateUrl: './cart-card.component.html',
  styleUrls: ['./cart-card.component.scss'],
})
export class CartCardComponent implements OnInit, OnDestroy, OnChanges {
  userInfo;
  // cart = [];
  user = this.authService.userValue;
  isLoggedIn = this.user && this.user.auth_token;
  isChecked = false;

  @Input() cart: any;
  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    private authService: AuthService,
    private cartService: CartService,
    private common: CommonService,
    private router: Router,
    private api: ApiService,
    private translate: TranslateService
  ) {}
  previousUrl;
  ngOnChanges(val) {
    this.validateCheckAll();
  }
  ngOnInit() {
    this.authService.getUser().then((res) => {
      this.userInfo = res;
      this.isLoggedIn = this.userInfo && this.userInfo.auth_token;

      if (!this.isLoggedIn) {
        return;
      }
      // this.isChecked = false;
    });
  }
  ionViewWillEnter() {
    this.validateCheckAll();
  }
  validateCheckAll() {
    let result = _.filter(this.cart, (ele) => {
      return ele.isChecked === true;
    });
    if (this.cart.length >= 0) {
      if (result.length === this.cart.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
    }
  }
  getCarts() {
    return new Promise((resolve, reject) => {
      this.cartService
        .getCart({ userInfo: this.userInfo })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  decreaseCartItem(product) {
    let body = {
      userInfo: this.userInfo,
      product: product,
    };

    this.cartService.decreaseProduct(body).then((res) => {
      this.getCarts().then((res: any) => {
        if (res.length > 0 && this.cart.length > 0) {
          let fndIdx = -1;
          for (let i = 0; i <= res.length - 1; i++) {
            fndIdx = -1;
            fndIdx = _.findIndex(this.cart, (ele) => {
              return ele.id === res[i].id;
            });
            if (fndIdx !== -1) {
              this.cart[fndIdx]['id'] = res[i]['id'];
              this.cart[fndIdx]['product'] = res[i]['product'];
              this.cart[fndIdx]['quantity'] = res[i]['quantity'];
            }
          }

          for (let i = 0; i <= this.cart.length - 1; i++) {
            fndIdx = -1;
            fndIdx = _.findIndex(res, (ele) => {
              return ele.id === this.cart[i].id;
            });
            if (fndIdx === -1) {
              this.cart.splice(i, 1);
            }
          }
        } else {
          this.cart = res;
        }
      });
    });
  }

  increaseCartItem(product) {
    let body = {
      userInfo: this.userInfo,
      product: _.cloneDeep(product),
    };

    body.product.quantity = 1;
    this.cartService.addProduct(body).then(
      (res) => {
        this.getCarts()
          .then((res: any) => {
            _.merge(this.cart, res);
          })
          .catch((err) => {
            this.common.presentToast(err);
          });
      },
      (err) => {
        this.common.presentToast({ message: err.message });
      }
    );
  }

  removeCartItem(product) {
    let body = {
      userInfo: this.userInfo,
      product: product,
    };
    this.cartService.removeProduct(body).then((res) => {
      this.getCarts().then((res: any) => {
        if (res.length > 0 && this.cart.length > 0) {
          let remaining_ids = _.map(res, (ele) => {
            return ele.id;
          });
          let new_cart = _.filter(this.cart, (ele) => {
            return _.includes(remaining_ids, ele.id);
          });

          this.cart = _.cloneDeep(new_cart);
        } else {
          this.cart = res;
        }
      });
    });
  }

  removeCartItems(items) {
    let body = {
      userInfo: this.userInfo,
      productsIds: _.map(items, 'id') || [],
    };

    this.cartService.removeProducts(body).then(
      (res) => {
        this.isChecked = false;
        this.getCarts().then(
          (res: any) => {
            if (res.length > 0 && this.cart.length > 0) {
              let remaining_ids = _.map(res, (ele) => {
                return ele.id;
              });
              let new_cart = _.filter(this.cart, (ele) => {
                return _.includes(remaining_ids, ele.id);
              });

              this.cart = _.cloneDeep(new_cart);
            } else {
              this.cart = res;
            }
          },
          (err) => {
            this.common.presentToast(err);
          }
        );
      },
      (err) => {
        this.common.presentToast(err);
      }
    );
  }

  async presentDeleteConfirm() {
    let selectedItems =
      _.filter(this.cart, (ele) => {
        return ele.isChecked === true;
      }) || [];
    if (selectedItems.length <= 0) {
      this.common.presentToast({
        message: 'Please select product(s).',
      });
      return;
    }
    const alert = await this.alertController.create({
      // header: "Delete all",
      message: 'Do you want to remove ' + selectedItems.length + ' items',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {},
        },
        {
          text: 'Yes',
          handler: () => {
            this.removeCartItems(selectedItems);
          },
        },
      ],
    });

    await alert.present();
  }

  ionViewWillLeave() {
    this.cart = [];
  }
  ngOnDestroy() {}

  goToCheckout() {
    let ids = _.filter(this.cart, (ele) => {
      if (ele.isChecked) {
        return ele['id'];
      }
    });

    if (ids.length <= 0) {
      this.common.presentToast({
        message: this.translate.instant('Select at-least one item to checkout'),
      });
    } else {
      let ids2 = _.map(ids, 'id');
      localStorage.setItem('cartId', JSON.stringify(ids2));

      this.getCalculateOrder(ids2)
        .then((res) => {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              formData: JSON.stringify(ids2),
            },
          };

          this.router.navigate(['/checkout'], navigationExtras);
        })
        .catch(() => {
          this.getCarts().then((res: any) => {
            this.cart = res;
          });
        });
    }
  }

  getCalculateOrder(ids) {
    return new Promise((resolve, reject) => {
      // console.log(this.selectedAddress["id"]);
      this.api
        .getCalculateOrder({
          userInfo: this.userInfo,
          carts: ids,
          addressId: '',
          selfPickup: false,
          voucherCode: '',
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.common.presentToast(err);
            reject(err);
          }
        );
    });
  }

  openProduct(product) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        formData: JSON.stringify(product),
      },
    };
    this.router.navigate(['/single-product-view'], navigationExtras);
  }

  getTotal() {
    let crt = _.filter(this.cart, (ele) => {
      return ele.isChecked;
    });

    return crt
      .reduce((i, j) => i + j.product.discountedPrice * j.quantity, 0)
      .toFixed(2);
  }

  checkAllChange() {
    this.isChecked = !this.isChecked;
    for (let i = 0; i <= this.cart.length - 1; i++) {
      this.cart[i].isChecked = this.isChecked;
    }
  }

  itemChecked(item) {
    item.isChecked = !item.isChecked;
    this.validateCheckAll();
    this.getCheckedId();

    this.getTotal();
  }

  getCheckedId() {
    let ids = _.filter(this.cart, (ele) => {
      if (ele.isChecked) {
        return ele['id'];
      }
    });

    if (ids.length > 0) {
      let ids2 = _.map(ids, 'id');
      localStorage.setItem('cartId', JSON.stringify(ids2));
    }
  }
}
