import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  FileTransfer,
  FileTransferObject,
  FileUploadOptions,
} from '@ionic-native/file-transfer/ngx';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  currentAccessToken = null;
  language = '';
  constructor(
    private http2: HttpClient,
    private transfer: FileTransfer,
    private _storage: Storage,
    private platform: Platform,
    private common: CommonService
  ) {
    this.platform
      .ready()
      .then(async () => {
        this.language = (await this._storage.get('appLanguage')) || 'en-US';
        // console.log(this.language);
        return this.loadToken();
      })
      .then(async () => {
        // this.language = (await this._storage.get('appLanguage')) || 'en-US';
        console.log(this.language);
      });
  }

  httpOptions = {};

  getHttpOption() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.language || 'en-US',
      }),
    };
  }
  getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': this.language || 'en-US',
    });
  }

  async loadToken() {
    let token = await this._storage.get('user');
    if (token) {
      this.currentAccessToken = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  register(body) {
    let url = this.common.getBaseUrl() + '/api/v1/account/register';
    return this.http2
      .post<any>(url, body, this.getHttpOption())
      .pipe(retry(0), catchError(this.handleError));
  }

  login(body) {
    let url = this.common.getBaseUrl() + '/api/v1/account/login';

    return this.http2
      .post<any>(url, body, this.getHttpOption())
      .pipe(retry(0), catchError(this.handleError));
  }

  verifyOTP(body) {
    let url = this.common.getBaseUrl() + '/api/v1/account/verify/otp';
    return this.http2
      .post<any>(url, body, this.getHttpOption())
      .pipe(retry(0), catchError(this.handleError));
  }

  submitForgotPassword(body) {
    let url = this.common.getBaseUrl() + '/api/v1/account/submitforgetpassword';
    return this.http2
      .post<any>(url, body, this.getHttpOption())
      .pipe(retry(0), catchError(this.handleError));
  }

  forgotPassword(body) {
    let url = this.common.getBaseUrl() + '/api/v1/account/forgetpassword';
    return this.http2
      .post<any>(url, body, this.getHttpOption())
      .pipe(retry(0), catchError(this.handleError));
  }

  getDashboard(body) {
    let url = this.common.getBaseUrl() + '/api/v1/dashboard';
    return this.http2
      .get<any>(url, this.getHttpOption())
      .pipe(retry(0), catchError(this.handleError));
  }

  getCategory(body) {
    let url = this.common.getBaseUrl() + '/api/v1/category';
    if (this.language === '') {
      this.language = body.lang;
    }

    return this.http2
      .get<any>(url, this.getHttpOption())
      .pipe(retry(0), catchError(this.handleError));
  }

  getProductsByCategory(body) {
    let url =
      this.common.getBaseUrl() +
      '/api/v1/category/' +
      body.category +
      '/product';
    const headers = this.getHeaders();

    const params = {
      skip: body.skip || 0,
      take: body.take || 15,
      sort: body.sort || 0,
    };

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  getProductsByBrand(body) {
    let url = this.common.getBaseUrl() + '/api/v1/product/brand/' + body.brand;
    const headers = this.getHeaders();

    const params = {
      skip: body.skip || 0,
      take: body.take || 15,
      sort: body.sort || 0,
    };

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  getProductsByBrandAndCategory(body) {
    let url =
      this.common.getBaseUrl() +
      '/api/v1/product/brand/' +
      body.brand +
      '/category/' +
      body.category;
    const headers = this.getHeaders();

    const params = {
      skip: body.skip || 0,
      take: body.take || 15,
      sort: body.sort || 0,
    };

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  getProducts(body) {
    let url = this.common.getBaseUrl() + '/api/v1/product/list';
    const headers = this.getHeaders();

    const params = {
      skip: body.skip || 0,
      take: body.take || 15,
      sort: body.sort || 0,
    };

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  getProduct(body, lang) {
    this.language = lang;
    let url = this.common.getBaseUrl() + '/api/v1/product/' + body.id;
    const headers = this.getHeaders();

    let options = {
      headers: headers,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  searchProduct(body) {
    let url = this.common.getBaseUrl() + '/api/v1/product';
    const headers = this.getHeaders();

    const params = {
      skip: body.skip || 0,
      take: body.take || 15,
      sort: body.sort || 0,
      search: body.search || '',
    };

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  getUserProfile(body) {
    let url = this.common.getBaseUrl() + '/api/v1/account/profile';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.auth_token);

    let options = {
      headers: headers,
    };

    // console.log(url);
    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  updateProfile(body) {
    let url = this.common.getBaseUrl() + '/api/v1/account/profile';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    let formData = {
      fullName: body.formData.fullName,
      phoneNumber: body.formData.phoneNumber,
      profilePictureAttachmentId: body.formData.profilePictureAttachmentId,
    };

    return this.http2
      .put<any>(url, formData, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    // console.log(error);
    let errorObj = {
      message: null,
      code: null,
    };
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error['errorMessage']);
    } else if (error.status === 500) {
      errorObj.message = 'Internal server error (code:500)';
      errorObj.code = _.isUndefined(error.status) ? '' : error.status;
    } else if (error.status === 401) {
    } else {
      errorObj.message = _.isString(error)
        ? error
        : error.error['errorMessage'];
      if (errorObj.message === '') {
        if (error.error !== '') {
          errorObj.message = error.error;
        }
      }
      errorObj.code = _.isUndefined(error.status) ? '' : error.status;
    }
    // return an observable with a user-facing error message
    return throwError(errorObj);
  }

  getAddress(body) {
    let url = this.common.getBaseUrl() + '/api/v1/address';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  addAddress(body) {
    let url = this.common.getBaseUrl() + '/api/v1/address';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };
    // console.log(body.formData);
    return this.http2
      .post<any>(url, body.formData, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  deleteAddress(body) {
    let url = this.common.getBaseUrl() + '/api/v1/address/' + body.id;

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .delete<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  updateAddress(body) {
    let url = this.common.getBaseUrl() + '/api/v1/address/' + body.id;
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .put<any>(url, body.formData, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  updateAddressPrimary(body) {
    let url =
      this.common.getBaseUrl() + '/api/v1/address/' + body.id + '/primary';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .put<any>(url, {}, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  getOrders(body) {
    let url = this.common.getBaseUrl() + '/api/v1/order';
    const params = {
      skip: body.skip || 0,
      take: body.take || 8,
    };

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  createOrder(body) {
    let url = this.common.getBaseUrl() + '/api/v1/order';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .post<any>(url, body.order, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  getOrderSummary(body) {
    let url = this.common.getBaseUrl() + '/api/v1/order/summary';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .post<any>(url, { cartIds: body.carts }, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  getCalculateOrder(body) {
    let url = this.common.getBaseUrl() + '/api/v1/order/calculate';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };
    // console.log(body);

    let reqParam = {
      cartIds: body.carts,
      addressId: body.addressId === '' ? null : body.addressId,
      selfPickup: body.selfPickup,
      voucherCode: body.voucherCode,
    };

    return this.http2
      .post<any>(url, reqParam, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  getOrderById(body) {
    let url = this.common.getBaseUrl() + '/api/v1/order/' + body.id;
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  getCarts(body) {
    let url = this.common.getBaseUrl() + '/api/v1/cart';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  addProductToCart(body) {
    let url = this.common.getBaseUrl() + '/api/v1/cart';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .post<any>(url, body.product, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  removeProductFromCart(body) {
    let url = this.common.getBaseUrl() + '/api/v1/cart/' + body.id;
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .delete<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  removeProductsFromCart(bodies) {
    let url = this.common.getBaseUrl() + '/api/v1/cart/batch/delete';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + bodies.userInfo.auth_token);

    let options = {
      headers: headers,
    };
    let body = { ids: bodies.ids };

    return this.http2
      .post<any>(url, body, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  updateCartQuantity(body) {
    let url = this.common.getBaseUrl() + '/api/v1/cart/' + body.product.id;

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .put<any>(url, { quantity: body.product.quantity }, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  paymentSuccess(body) {
    let url =
      this.common.getBaseUrl() + '/api/v1/order/' + body.orderid + '/success';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .put<any>(url, {}, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  paymentFailed(body) {
    let url =
      this.common.getBaseUrl() + '/api/v1/order/' + body.orderid + '/fail';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .put<any>(url, {}, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  getAgentOrders(body) {
    let url = this.common.getBaseUrl() + '/api/agent/order';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    const params = {
      skip: body.skip || 0,
      take: body.take || 15,
      orderStatus: body.orderStatus,
    };

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }
  updateOrder(body) {
    let url =
      this.common.getBaseUrl() +
      '/api/agent/order/' +
      body.order.id +
      '/delivered';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .put<any>(url, {}, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  getAgentCommission(body) {
    let url = this.common.getBaseUrl() + '/api/agent/commission';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    const params = {};

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  getAgentWithdrawal(body) {
    let url = this.common.getBaseUrl() + '/api/agent/commission/withdrawal';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    const params = {
      skip: body.skip || 0,
      take: body.take || 15,
      status: body.status,
    };

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  submitAgentWithdrawal(body) {
    let url = this.common.getBaseUrl() + '/api/agent/commission/withdrawal';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .post<any>(url, {}, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  uploadImage(body) {
    // Destination URL
    let url = this.common.getBaseUrl() + '/api/v1/attachment';

    // File for Upload
    var targetPath = body.attachment.filePath;

    var options: FileUploadOptions = {
      fileKey: 'file',
      fileName: body.attachment.name,
      chunkedMode: false,
      mimeType: 'multipart/form-data',
      headers: {
        Authorization: 'Bearer ' + body.userInfo.auth_token,
        'Accept-Language': this.language || 'en-US',
      },
    };

    const fileTransfer: FileTransferObject = this.transfer.create();

    return fileTransfer.upload(targetPath, url, options);
  }

  uploadAttachment(body) {
    let url = this.common.getBaseUrl() + '/api/v1/attachment';
    return this.http2
      .post(url, body)
      .pipe(retry(0), catchError(this.handleError));
  }

  payAgain(body) {
    let url =
      this.common.getBaseUrl() + '/api/v1/order/' + body.id + '/payment';

    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    const params = {
      isWeb: true,
    };

    let options = {
      headers: headers,
    };

    return this.http2
      .post<any>(url, params, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  buyAgain(body) {
    let url =
      this.common.getBaseUrl() + '/api/v1/cart/order/' + body.id + '/buyagain';
    const headers = this.getHeaders();
    headers.append('Authorization', 'Bearer ' + body.userInfo.auth_token);

    let options = {
      headers: headers,
    };

    return this.http2
      .post<any>(url, {}, options)
      .pipe(retry(0), catchError(this.handleError));
  }

  getBrand(body) {
    let url = this.common.getBaseUrl() + '/api/v1/brand';

    return this.http2
      .get<any>(url, this.getHttpOption())
      .pipe(retry(0), catchError(this.handleError));
  }
  getBrandByCategory(body) {
    let url = this.common.getBaseUrl() + '/api/v1/brand';

    const headers = this.getHeaders();

    const params = {
      categoryId: body.categoryId,
    };

    let options = {
      headers: headers,
      params: params,
    };

    return this.http2
      .get<any>(url, options)
      .pipe(retry(0), catchError(this.handleError));
  }
}
