import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import { CommonService } from 'src/app/services/common.service';
import { NavigationExtras, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { Storage } from '@ionic/storage-angular';
@Component({
  selector: 'app-app-nav-bar',
  templateUrl: './app-nav-bar.component.html',
  styleUrls: ['./app-nav-bar.component.scss'],
})
export class AppNavBarComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private apiServ: ApiService,
    private common: CommonService,
    private router: Router,
    private _storage: Storage
  ) {}
  categories;
  categories2;
  subCat = [];
  sub2Cat = [];
  language;
  ngOnInit() {
    this._storage
      .get('appLanguage')
      .then((res) => {
        console.log(res);
        this.language = res || 'en-US';
        return this.getCategories();
      })
      .then((res) => {
        return this.getDashboard();
      });

    // this.getCategories()
    //   .then((res) => {
    //     return this.getDashboard();
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     return this._storage.get('appLanguage') || 'en-US';
    //   })
    //   .then((res) => {
    //     this.language = res;
    //   });
  }

  getCategories() {
    return new Promise((resolve, reject) => {
      this.apiServ.getCategory({ lang: this.language }).subscribe(
        (res) => {
          if (res.length > 0) {
            this.categories2 = _.cloneDeep(res) || [];
            this.categories = res || [];
          }

          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getMainCat() {
    this.categories2 = _.cloneDeep(this.categories);
  }
  getChild(cat) {
    this.subCat = cat.childCategories || [];
  }
  getSubChild(cat) {
    this.sub2Cat = cat.childCategories || [];
  }
  openMenu(cat, type) {
    // type : category,newArrival,promotion
    let sub = _.cloneDeep(cat);
    let det = _.cloneDeep(cat);
    delete sub.childCategories;

    let param = {
      sub: sub,
      det: det,
      type: type,
    };

    let navigationExtras: NavigationExtras = {
      queryParams: {
        formData: JSON.stringify(param),
      },
    };

    this.trigger.closeMenu();
    this.subCat = [];
    this.sub2Cat = [];
    this.categories2 = [];
    this.router.navigate(['/product-list'], navigationExtras);
  }
  dashboardData = { promotionCategory: {}, newArrivalCategory: {} };
  getDashboard() {
    return new Promise((resolve, reject) => {
      this.apiServ.getDashboard({}).subscribe(
        (res) => {
          this.dashboardData = res;
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
