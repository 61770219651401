import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { ApiService } from "src/app/services/api.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  @Input() dataInput: string;

  formData = {
    userInfo: {},
    userProfile: {
      fullName: "",
      email: "",
      phoneNumber: "",
      profilePictureAttachmentId: "",
      profilePictureAttachmentUrl: "",
    },
  };
  constructor(
    private api: ApiService,
    private common: CommonService,
    private translate: TranslateService,
    private alertController: AlertController,
    private modalController: ModalController,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {
    if (this.dataInput) {
      this.formData = JSON.parse(this.dataInput);
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size / 1024 / 1024 > 2) {
        this.common.presentToast({
          message: "The selected photo exceed 1mb, please try again.",
        });
        event.target.value = "";
      } else {
        if (event.target.files && event.target.files[0]) {
          let currentFile = event.target.files[0];
          var reader = new FileReader();

          reader.onload = (event: any) => {};

          reader.onloadend = (event: any) => {
            const uploadData = new FormData();
            uploadData.append("file", currentFile, currentFile.name);

            this.api.uploadAttachment(uploadData).subscribe(
              (event) => {
                // if (event["type"] === HttpEventType.UploadProgress) {
                //   // console.log(
                //     Math.round((100 * event["loaded"]) / event["total"])
                //   );
                // } else
                // if (event instanceof HttpResponse) {
                // // console.log(event);

                this.formData.userProfile.profilePictureAttachmentId =
                  event["id"];

                this.updateProfile();
                // }
              },
              (err) => {
                // console.log(err);
              }
            );
          };

          reader.readAsDataURL(currentFile);
        }
      }
    }
  }

  async presentAlert(errMsg) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "",
      message: errMsg,
      buttons: [
        {
          text: "OK",
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  saveBtnClicked() {
    this.updateProfile();
  }

  async updateProfile() {
    const loading = await this.loadingController.create({
      message: "Updating profile...",
    });
    await loading.present();

    let body = {
      userInfo: this.formData.userInfo,
      formData: this.formData.userProfile,
    };

    this.api.updateProfile(body).subscribe(
      (res) => {
        loading
          .dismiss()
          .then(() => {
            this.common.presentToast({ message: "Profile updated success." });
          })
          .then((res) => {
            return this.modalController.dismiss({
              updatedSuccess: true,
              cancel: false,
            });
          });
      },
      (err) => {
        loading.dismiss();
        this.common.presentToast(err.message);
      }
    );
  }

  cancel() {
    this.modalController.dismiss({
      updatedSuccess: false,
      cancel: true,
    });
  }
}
