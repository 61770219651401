import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}
  goToLink(url) {
    window.open(url, '_blank');
  }
  goToPrivacyPolicy(val: any) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     data: 'privacy',
    //   },
    // };

    // this.router.navigateByUrl('/privacy-policy?id=privacy');

    this.router.navigate(['/privacy-policy', val]);
  }
  goToAboutUs() {
    this.router.navigateByUrl('/about');
  }
}
