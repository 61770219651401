import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  url = '';
  language = '';
  constructor(
    private router: Router,
    private http: HttpClient,
    private _storage: Storage,
    private platform: Platform,
    private common: CommonService
  ) {
    this.userSubject = new BehaviorSubject<any>(null);
    this.user = this.userSubject.asObservable();
    let gtUser;
    this.platform
      .ready()
      .then(() => {
        return this.getUser();
      })
      .then((res) => {
        gtUser = res;
        return gtUser;
      })
      .then(async (newUser) => {
        this.language = (await this._storage.get('appLanguage')) || 'en-US';
        console.log(this.language);
        if (newUser) {
          this.userSubject.next(newUser);
        }
      });
    // .then((res) => {
    //   if (res) {
    //     this.userSubject.next(res);
    //   }
    // });
  }

  public get userValue() {
    return this.userSubject.value;
  }

  httpOptions = {};
  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.language || 'en-US',
      }),
    };
  }

  login(user) {
    let url = this.common.getBaseUrl() + '/api/v1/account/login';
    return this.http.post<any>(url, user, this.getHttpOptions()).pipe(
      map((user) => {
        this.userSubject.next(user);
        this._storage.set('user', user);
        this.startRefreshTokenTimer();
        return user;
      })
    );
  }

  logout() {
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this._storage.remove('user');

    this.router.navigate(['']);
  }

  refreshToken() {
    let url = this.common.getBaseUrl() + '/api/v1/account/refresh';
    return this.http.post<any>(url, {}, this.getHttpOptions()).pipe(
      map((user) => {
        this.userSubject.next(user);
        this._storage.set('user', user);
        this.startRefreshTokenTimer();
        return user;
      })
    );
  }

  // helper methods
  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token

    // set a timeout to refresh the token a minute before it expires
    const expires = this.userValue['expires_in'] || 7200;
    let expired = (expires - 60) * 1000;

    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken().subscribe();
    }, expired);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  // User profile
  getUserProfile() {
    let url = this.common.getBaseUrl() + '/api/v1/account/profile';
    return this.http.get<any>(url, this.getHttpOptions()).pipe(
      map((profile) => {
        return profile;
      })
    );
  }

  async getUser() {
    return this._storage.get('user');
  }
}
