import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/tab1/tab1.module').then((m) => m.Tab1PageModule),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./home/tab3/tab3.module').then((m) => m.Tab3PageModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./home/tab4/tab4.module').then((m) => m.Tab4PageModule),
  },

  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
  },

  {
    path: 'single-product-view',
    loadChildren: () =>
      import('./single-product-view/single-product-view.module').then(
        (m) => m.SingleProductViewPageModule
      ),
  },
  {
    path: 'my-orders',
    loadChildren: () =>
      import('./my-orders/my-orders.module').then((m) => m.MyOrdersPageModule),
  },
  {
    path: 'my-addresses',
    loadChildren: () =>
      import('./my-addresses/my-addresses.module').then(
        (m) => m.MyAddressesPageModule
      ),
  },
  {
    path: 'account-settings',
    loadChildren: () =>
      import('./account-settings/account-settings.module').then(
        (m) => m.AccountSettingsPageModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'order-success',
    loadChildren: () =>
      import('./order-success/order-success.module').then(
        (m) => m.OrderSuccessPageModule
      ),
  },
  {
    path: 'order-failed',
    loadChildren: () =>
      import('./order-failed/order-failed.module').then(
        (m) => m.OrderFailedPageModule
      ),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutPageModule),
  },
  {
    path: 'product-list',
    loadChildren: () =>
      import('./product-list/product-list.module').then(
        (m) => m.ProductListPageModule
      ),
  },
  {
    path: 'order-validate',
    loadChildren: () =>
      import('./order-validate/order-validate.module').then(
        (m) => m.OrderValidatePageModule
      ),
  },
  {
    path: 'track-order',
    loadChildren: () =>
      import('./track-order/track-order.module').then(
        (m) => m.TrackOrderPageModule
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'forget-password',
    loadChildren: () =>
      import('./forget-password/forget-password.module').then(
        (m) => m.ForgetPasswordPageModule
      ),
  },
  {
    path: 'otp',
    loadChildren: () => import('./otp/otp.module').then((m) => m.OtpPageModule),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'password-success',
    loadChildren: () =>
      import('./password-success/password-success.module').then(
        (m) => m.PasswordSuccessPageModule
      ),
  },
  {
    path: 'password-failed',
    loadChildren: () =>
      import('./password-failed/password-failed.module').then(
        (m) => m.PasswordFailedPageModule
      ),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./terms-and-conditions/terms-and-conditions.module').then(
        (m) => m.TermsAndConditionsPageModule
      ),
  },
  {
    path: 'privacy-policy/:id',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },
  {
    path: 'refund-policy',
    loadChildren: () =>
      import('./refund-policy/refund-policy.module').then(
        (m) => m.RefundPolicyPageModule
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./about/about.module').then((m) => m.AboutPageModule),
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./help/help.module').then((m) => m.HelpPageModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchPageModule),
  },
  {
    path: 'add-new-address',
    loadChildren: () =>
      import('./add-new-address/add-new-address.module').then(
        (m) => m.AddNewAddressPageModule
      ),
  },
  {
    path: 'verify',
    loadChildren: () =>
      import('./verify-email/verify-email.module').then(
        (m) => m.VerifyEmailPageModule
      ),
  },
  {
    path: 'select-position',
    loadChildren: () =>
      import('./select-position/select-position.module').then(
        (m) => m.SelectPositionPageModule
      ),
  },
  {
    path: 'agent',
    loadChildren: () =>
      import('./agent/agent.module').then((m) => m.AgentPageModule),
  },
  {
    path: 'agent-orders',
    loadChildren: () =>
      import('./agent-orders/agent-orders.module').then(
        (m) => m.AgentOrdersPageModule
      ),
  },
  {
    path: 'agent-order-detail',
    loadChildren: () =>
      import('./agent-order-detail/agent-order-detail.module').then(
        (m) => m.AgentOrderDetailPageModule
      ),
  },
  {
    path: 'agent-commission',
    loadChildren: () =>
      import('./agent-commission/agent-commission.module').then(
        (m) => m.AgentCommissionPageModule
      ),
  },
  {
    path: 'agent-commission-detail',
    loadChildren: () =>
      import('./agent-commission-detail/agent-commission-detail.module').then(
        (m) => m.AgentCommissionDetailPageModule
      ),
  },

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
