import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-brand-selection",
  templateUrl: "./brand-selection.component.html",
  styleUrls: ["./brand-selection.component.scss"],
})
export class BrandSelectionComponent implements OnInit {
  @Input() brandLists = [];
  @Output() checkChange = new EventEmitter();
  constructor() {}

  ngOnInit() {
    console.log(this.brandLists);
  }
  itemChecked(val) {
    this.checkChange.emit(val);
  }
}
