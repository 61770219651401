import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";

// import { Drivers } from "@ionic/storage-angular";
import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { UniqueDeviceID } from "@ionic-native/unique-device-id/ngx";
import { SpinnerDialog } from "@ionic-native/spinner-dialog/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import {
  NativeHttpModule,
  NativeHttpBackend,
  NativeHttpFallback,
} from "ionic-native-http-connection-backend";

import { Platform } from "@ionic/angular";
import {
  HttpClientModule,
  HttpClient,
  HttpBackend,
  HttpXhrBackend,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateConfigService } from "./translate-config.service";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginModalPage } from "./components/login-modal/login-modal.page";

import { appInitializer } from "./_helpers/app.initializer";
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { AuthService } from "./services/auth.service";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { File } from "@ionic-native/file/ngx";
import { FilePath } from "@ionic-native/file-path/ngx";
import { FileTransfer } from "@ionic-native/file-transfer/ngx";
import { SQLite } from "@ionic-native/sqlite/ngx";
import { SharedModule } from "./shared.module";
import { ScrollbarThemeModule } from "./directive/scrollbar.directive";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { IvyCarouselModule } from "angular-responsive-carousel";

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
@NgModule({
  declarations: [AppComponent, LoginModalPage],
  entryComponents: [LoginModalPage],
  imports: [
    SharedModule,
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NativeHttpModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    BrowserAnimationsModule,
    IvyCarouselModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    UniqueDeviceID,
    TranslateConfigService,
    SpinnerDialog,
    Geolocation,
    NativeGeocoder,
    InAppBrowser,
    AppVersion,
    Camera,
    File,
    SQLite,
    WebView,
    FilePath,
    FileTransfer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HttpBackend,
      useClass: NativeHttpFallback,
      deps: [Platform, NativeHttpBackend, HttpXhrBackend],
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   multi: true,
    //   deps: [AuthService],
    // },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  exports: [LoginModalPage],
  bootstrap: [AppComponent],
})
export class AppModule {}
