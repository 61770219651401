import { Location } from "@angular/common";
import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

import * as _ from "lodash";

import { ApiService } from "src/app/services/api.service";
import { CartService } from "src/app/services/cart.service";
import { CommonService } from "src/app/services/common.service";

import { AddressCardComponent } from "../address-card/address-card.component";
@Component({
  selector: "app-checkout-card",
  templateUrl: "./checkout-card.component.html",
  styleUrls: ["./checkout-card.component.scss"],
})
export class CheckoutCardComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    public cartService: CartService,
    private common: CommonService,
    private api: ApiService,
    private location: Location,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private modalController: ModalController
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params && params.formData) {
        this.ids = JSON.parse(params.formData);
      }
    });
  }
  ids = [];
  cart = [];
  userInfo;
  addresses = [];
  orderSummary;
  selectedAddress = {
    id: "",
    primary: false,
    fullName: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    state: "",
  };
  totalCost = 0;
  grandTotal = 0;
  shippingCost = 0;
  discount = 0;
  couponCode = "";
  couponApplied = false;
  remarks = "";
  ngOnInit() {
    this.common
      .getUserInfo("user")
      .then((userInfo) => {
        this.userInfo = userInfo;
        return this.getCalculateOrder();
      })
      .then((res: any) => {
        this.doTotalCalculation();
        this.getAddress();
      });
  }
  ngOnDestroy() {
    this.discount = 0;
    this.couponCode = "";
    this.couponApplied = false;
    this.remarks = "";
  }
  ngAfterViewChecked() {}
  getCarts() {
    return new Promise((resolve, reject) => {
      this.cartService
        .getCart({ userInfo: this.userInfo })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrderSummary() {
    return new Promise((resolve, reject) => {
      this.api
        .getOrderSummary({ userInfo: this.userInfo, carts: this.ids })
        .subscribe(
          (res) => {
            this.orderSummary = res;
            // console.log(this.orderSummary);
            resolve("");
          },
          (err) => {
            this.common.presentToast(err);
            reject(err);
          }
        );
    });
  }
  addressChange() {
    this.getCalculateOrder()
      .then((res) => {
        this.doTotalCalculation();
      })
      .catch((err) => {
        this.common.presentToast(err);
      });
  }
  getCalculateOrder() {
    return new Promise((resolve, reject) => {
      // console.log(this.selectedAddress["id"]);
      this.api
        .getCalculateOrder({
          userInfo: this.userInfo,
          carts: this.ids,
          addressId: this.selectedAddress["id"],
          selfPickup: false,
          voucherCode: this.couponCode,
        })
        .subscribe(
          (res) => {
            // console.log(res);
            this.orderSummary = res.items;
            this.shippingCost = res.shippingAmount || 0;
            this.discount = res.discountAmount || 0;
            resolve(res);
          },
          (err) => {
            this.common.presentToast(err);
            reject(err);
          }
        );
    });
  }

  back() {
    this.location.back();
  }

  getAddress() {
    let body = { userInfo: this.userInfo };
    this.api.getAddress(body).subscribe(
      (res: any) => {
        this.addresses = res;

        if (this.addresses.length > 0) {
          let found = _.find(this.addresses, (ele) => {
            return ele.primary;
          });
          if (found) {
            this.selectedAddress = found;
          } else {
            this.selectedAddress = this.addresses[0];
          }
        }
      },
      (err) => {
        this.common.presentToast(err.message);
      }
    );
  }

  lessQty(index) {
    if (this.cart[index].quantity > 0) {
      this.cart[index].quantity = this.cart[index].quantity - 1;
      this.doTotalCalculation();
    }
    if (this.cart[index].quantity == 0) {
      this.removeFromCart(index);
    }
  }

  addQty(index) {
    if (this.cart[index].quantity >= 0 && this.cart[index].quantity <= 25) {
      this.cart[index].quantity = this.cart[index].quantity + 1;
    }
    this.doTotalCalculation();
  }

  applyCoupon() {
    if (this.couponCode !== "") {
      this.getCalculateOrder().then((res) => {
        this.doTotalCalculation();
        this.simpleAlert("Hurray! Coupon Applied.");
        this.couponApplied = true;
      });
    } else {
      this.simpleAlert("Invalid Coupon Code!");
    }
  }

  removeCoupon() {
    this.couponCode = "";
    this.discount = 0;
    this.couponApplied = false;
    this.doTotalCalculation();
    this.simpleAlert("Coupon Removed!");
  }

  doTotalCalculation() {
    this.totalCost = 0;
    this.grandTotal = 0;
    for (let item of this.orderSummary) {
      this.totalCost += item.product?.discountedPrice * item.quantity;
    }
    if (this.totalCost - this.discount > 100) {
      this.shippingCost = 0;
    }

    this.grandTotal = this.shippingCost + this.totalCost - this.discount;
  }

  async removeFromCart(i) {
    const alert = await this.alertController.create({
      header: "Do you want to remove this item?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            // console.log("Action cancelled");
          },
        },
        {
          text: "Yes!",
          handler: () => {
            let body = {
              userInfo: this.userInfo,
              product: i,
            };
            this.cartService.removeProduct(body).then((res) => {
              this.getCarts().then((res: any) => {
                this.cart = res;
                this.toastAlert("Item removed from cart.", i);
              });
            });
          },
        },
      ],
    });

    await alert.present();
  }

  async toastAlert(msg, index) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1500,
    });
    toast.present();
    // this.cart.splice(index, 1);
    this.doTotalCalculation();
  }

  async simpleAlert(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1500,
    });
    toast.present();
  }

  validateOrder() {
    this.getCalculateOrder()
      .then((result) => {
        console.log(result);
        // console.log(result["shippingAmount"]);
        if (result["agentAssigned"] === false) {
          return this.proceedOrder(false);
        } else {
          // console.log("Proceed");
          return this.proceedOrder(false);
        }
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  createOrder(body) {
    return new Promise((resolve, reject) => {
      this.api.createOrder(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  goToAddress() {
    // this.router.navigate(["/add-new-address"], { replaceUrl: true });
    this.router.navigate(["/add-new-address"]);
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Join Us Now!!!",
      message:
        "No agent available currently at your area, <strong>join us</strong> now to enjoy delivery charges waiver. Click yes to know more & Our team will contact you soon.",
      buttons: [
        {
          text: "No",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            // console.log("Confirm Cancel: blah");
            this.proceedOrder(false);
          },
        },
        {
          text: "Yes",
          handler: () => {
            this.proceedOrder(true);
          },
        },
      ],
    });

    await alert.present();
  }

  async proceedOrder(val) {
    let body = {
      userInfo: this.userInfo,
      order: {
        cartIds: _.map(this.orderSummary, "id"),
        addressId: this.selectedAddress["id"],
        selfPickup: false,
        agentInterested: val,
        remark: this.remarks,
        voucherCode: this.couponCode,
        isWeb: true,
      },
    };

    let orderResult;

    this.createOrder(body)
      .then((res) => {
        // console.log(res);
        orderResult = res;
        return this.getCarts();
      })
      .then(() => {
        return this.common.presentToast({
          message: this.translate.instant("Order created successful."),
        });
      })
      .then(() => {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            formData: JSON.stringify(orderResult),
          },
        };
        this.router.navigate(["/order-validate"], navigationExtras);
      })
      .catch((err) => {
        this.common
          .presentToast({
            message: err.message,
          })

          .then(() => {
            this.router.navigate(["/order-failed"]);
          });
      });
  }

  async openSelectAddressModal() {
    const modal = await this.modalController.create({
      component: AddressCardComponent,
      cssClass: "my-custom-modal-css hide-card-shadow",
      componentProps: {
        isSelect: true,
        selectedAddress: this.selectedAddress,
      },
    });
    await modal.present();
    const data = await modal.onDidDismiss();

    if (data?.data?.address) {
      this.getAddresses().then(() => {
        this.selectedAddress = _.cloneDeep(data?.data?.address);
      });
    }
  }

  async getAddresses() {
    let body = { userInfo: this.userInfo };
    await this.api.getAddress(body).subscribe(
      (res: any) => {
        this.addresses = res;
      },
      (err) => {
        this.common.presentToast(err.message);
      }
    );
  }
}
