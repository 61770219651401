import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "./api.service";
import * as _ from "lodash";
@Injectable({
  providedIn: "root",
})
export class CartService {
  private cart = [];
  public cartItemCount = new BehaviorSubject(0);

  constructor(private api: ApiService) {
    // console.log("cart service constructor");
  }

  getCart(body) {
    return new Promise((resolve, reject) => {
      this.api.getCarts(body).subscribe(
        (res) => {
          this.cart = _.cloneDeep(res);

          this.getCartItemCount();
          resolve(this.cart);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getCartItemCount() {
    // console.log(_.sumBy(this.cart, "quantity"));
    if (_.sumBy(this.cart, "quantity") === 0) {
      this.cartItemCount.next(0);
    }
    if (_.sumBy(this.cart, "quantity") !== this.cartItemCount.value) {
      this.cartItemCount.next(_.sumBy(this.cart, "quantity"));
    }
    return this.cartItemCount;
  }

  addProduct(param) {
    let body = {
      userInfo: param.userInfo,
      product: {
        productId: param.product.product.id,
        quantity: param.product.quantity || 1,
      },
    };

    return new Promise((resolve, reject) => {
      this.api.addProductToCart(body).subscribe(
        (res) => {
          resolve("");
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  decreaseProduct(param) {
    return new Promise((resolve, reject) => {
      if (param.product.quantity - 1 === 0) {
        this.removeProduct(param)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        let body = {
          userInfo: param.userInfo,
          product: {
            id: param.product.id,
            quantity: param.product.quantity - 1,
          },
        };

        this.api.updateCartQuantity(body).subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
      }
    });
  }

  removeProduct(param) {
    let body = {
      userInfo: param.userInfo,
      id: param.product.id,
    };
    return new Promise((resolve, reject) => {
      this.api.removeProductFromCart(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  removeProducts(param) {
    let body = {
      userInfo: param.userInfo,
      ids: param.productsIds,
    };
    return new Promise((resolve, reject) => {
      this.api.removeProductsFromCart(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  clearCart() {
    this.cart = [];
    this.getCartItemCount();
  }
}
