import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import * as _ from "lodash";
import { from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Storage } from "@ionic/storage-angular";
import { environment } from "src/environments/environment";
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  url;
  constructor(private authService: AuthService, private _storage: Storage) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url

    return from(this.loadData()).pipe(
      switchMap((res) => {
        this.url = res["endpoint"];

        const user = res["token"] || this.authService.userValue;
        const isLoggedIn = user && user.auth_token;
        const isApiUrl = request.url.startsWith(this.url);
        const isRefreshUrl = request.url.startsWith(
          this.url + "/api/v1/account/refresh"
        );
        if (isLoggedIn && isApiUrl) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${user.auth_token}` },
          });
        }
        if (isLoggedIn && isRefreshUrl) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${user["refresh_token"]}` },
          });
        }

        return next.handle(request);
      })
    );
  }

  loadData() {
    let result = { endpoint: "", token: {} };
    return new Promise((resolve, reject) => {
      result.endpoint = environment.api_url;
      this._storage.get("user").then((res) => {
        result.token = res;
        resolve(result);
      });
    });
  }
}
