import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { LoginModalPage } from '../login-modal/login-modal.page';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() product;
  itemQty = 0;
  user = this.authService.userValue;
  isLoggedIn = this.user && this.user.auth_token;
  userInfo;

  constructor(
    private router: Router,
    private authService: AuthService,
    private common: CommonService,
    private cartService: CartService,
    private modalController: ModalController
  ) {
    this.authService.user.subscribe((res) => {
      if (res !== null) {
        this.user = res;
        this.isLoggedIn = this.user && this.user.auth_token;
        this.userInfo = this.user;
      }
    });
  }

  ngOnInit() {
    this.getUserInfo();
  }
  getUserInfo() {
    return new Promise((resolve, reject) => {
      this.common.getUserInfo('user').then((userInfo) => {
        this.userInfo = userInfo;
        resolve(this.userInfo);
      });
    });
  }
  ionViewWillEnter() {
    this.initData();
  }
  initData() {
    this.authService.getUser().then((res) => {
      if (res) {
        this.isLoggedIn = res && res.auth_token;
      }
    });
  }

  openProduct(product) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        formData: JSON.stringify(product),
      },
    };
    this.router.navigate(['/single-product-view'], navigationExtras);
  }
  addQty() {
    this.itemQty = Number(this.itemQty) + 1;
  }
  minusQty() {
    this.itemQty = Number(this.itemQty) - 1;

    if (this.itemQty <= 0) {
      this.itemQty = 0;
    }
  }
  async addToCart() {
    if (this.itemQty <= 0) {
      this.common.presentToast({
        message: 'Plese input atleast one quantity.',
      });
      return;
    }
    this.authService.getUser().then(async (res) => {
      this.isLoggedIn = res && res.auth_token;

      if (this.isLoggedIn) {
        let body = {
          userInfo: this.userInfo,
          product: { product: this.product, quantity: this.itemQty },
        };
        this.cartService
          .addProduct(body)
          .then((res) => {
            this.cartService
              .getCart({ userInfo: this.userInfo })
              .then((res) => {
                this.itemQty = 0;
                this.common.presentToast({ message: 'Added to cart.' });
              });
          })
          .catch((err) => {
            this.common.presentToast({ message: err.message });
          });
      } else {
        const modal = await this.modalController.create({
          component: LoginModalPage,
          cssClass: 'hide-card-shadow login-modal',
        });
        await modal.present();
        const data = await modal.onDidDismiss();

        if (data.data === 'success') {
          this.getUserInfo().then(() => {
            this.addToCart();
          });
        }
      }
    });
  }
  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
