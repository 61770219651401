import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-order-list-card',
  templateUrl: './order-list-card.component.html',
  styleUrls: ['./order-list-card.component.scss'],
})
export class OrderListCardComponent implements OnInit {
  constructor(
    private common: CommonService,
    private api: ApiService,
    private router: Router,
    private alertController: AlertController
  ) {}

  userInfo;
  orders;
  ngOnInit() {
    this.common.getUserInfo('user').then((userInfo) => {
      this.userInfo = userInfo;
      this.getOrders();
    });
  }

  getOrders() {
    const body = { userInfo: this.userInfo };
    this.api.getOrders(body).subscribe(
      (res) => {
        this.orders = res;
      },
      (err) => {
        this.common.presentToast(err.message);
      }
    );
  }

  getTotalQtyCount(details) {
    return _.sumBy(details, 'quantity');
  }

  getOrdersScrollLoad(val) {
    const body = {
      userInfo: this.userInfo,
      skip: this.orders.length || 0,
      take: 8,
    };
    this.api.getOrders(body).subscribe(
      (res) => {
        // console.log(res);
        this.orders = this.orders.concat(res);
        val.target.complete();
      },
      (err) => {
        // console.log(err);
        this.common.presentToast(err.message);
        val.target.complete();
      }
    );
  }
  getOrderStatus(val) {
    return this.common.getOrderStatus(val);
    // PENDING_PAYMENT = 0,
    // CANCELLED = 1,
    // PAYMENT_SUCCESS = 2,
    // PAYMENT_FAILED = 3
  }

  getColor(val) {
    if (val === 0) {
      return 'warning';
    }
    if (val === 1) {
      return 'medium';
    }
    if (val === 2) {
      return 'success';
    }
    if (val === 3) {
      return 'danger';
    }
    if (val === 4) {
      return 'primary';
    }
    if (val === 5) {
      return 'secondary';
    }
    if (val === 6) {
      return 'tertiary';
    }
    if (val === 7) {
      return 'primary';
    }

    // <ion-button color="primary">Primary</ion-button>
    // <ion-button color="secondary">Secondary</ion-button>
    // <ion-button color="tertiary">Tertiary</ion-button>
    // <ion-button color="success">Success</ion-button>
    // <ion-button color="warning">Warning</ion-button>
    // <ion-button color="danger">Danger</ion-button>
    // <ion-button color="light">Light</ion-button>
    // <ion-button color="medium">Medium</ion-button>
    // <ion-button color="dark">Dark</ion-button>
  }

  trackOrder(order) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        formData: JSON.stringify(order),
      },
    };

    this.router.navigate(['/track-order'], navigationExtras);
  }

  payAgain(order) {
    try {
      const body = { userInfo: this.userInfo, id: order.id };
      this.payAgainApi(body).then((res) => {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            formData: JSON.stringify(res),
          },
        };
        this.router.navigate(['/order-validate'], navigationExtras);
      });
    } catch (error) {
      this.common.presentToast({
        message: error.message,
      });
    }
  }
  payAgainApi(body) {
    return new Promise((resolve, reject) => {
      this.api.payAgain(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  buyAgain(order) {
    try {
      const body = { userInfo: this.userInfo, id: order.id };
      this.buyAgainApi(body).then((res) => {
        if (res['message'] === '') {
          this.router.navigate(['/cart']);
        } else {
          this.presentAlert(res['message']);
        }
      });
    } catch (error) {
      this.common.presentToast({
        message: error.message,
      });
    }
  }
  buyAgainApi(body) {
    return new Promise((resolve, reject) => {
      this.api.buyAgain(body).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  async presentAlert(errMsg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '',
      message: errMsg,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.router.navigate(['/cart']);
          },
        },
      ],
    });

    await alert.present();
  }
  segmentChanged() {}
}
