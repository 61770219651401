import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { AccountInfoCardComponent } from "./components/account-info-card/account-info-card.component";
import { AddressCardComponent } from "./components/address-card/address-card.component";
import { AppFooterComponent } from "./components/app-footer/app-footer.component";
import { AppHeaderComponent } from "./components/app-header/app-header.component";
import { AppNavBarComponent } from "./components/app-nav-bar/app-nav-bar.component";
import { CartCardComponent } from "./components/cart-card/cart-card.component";
import { CheckoutCardComponent } from "./components/checkout-card/checkout-card.component";
import { DeliveryStatusModalComponent } from "./components/delivery-status-modal/delivery-status-modal.component";
import { EditProfileComponent } from "./components/edit-profile/edit-profile.component";
import { LoginCardComponent } from "./components/login-card/login-card.component";

import { OrderListCardComponent } from "./components/order-list-card/order-list-card.component";
import { ProductCardComponent } from "./components/product-card/product-card.component";
import { ScrollbarThemeModule } from "./directive/scrollbar.directive";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

import { IvyCarouselModule } from "angular-responsive-carousel";
import { BrandSelectionComponent } from "./components/brand-selection/brand-selection.component";
import { DownloadModalComponent } from "./components/download-modal/download-modal.component";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    ScrollbarThemeModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    IvyCarouselModule,
  ],
  declarations: [
    ProductCardComponent,
    AppHeaderComponent,
    AccountInfoCardComponent,
    LoginCardComponent,
    CartCardComponent,
    CheckoutCardComponent,
    AppNavBarComponent,
    OrderListCardComponent,
    AddressCardComponent,
    DeliveryStatusModalComponent,
    AppFooterComponent,
    EditProfileComponent,
    BrandSelectionComponent,
    DownloadModalComponent,
  ],
  exports: [
    ProductCardComponent,
    AppHeaderComponent,
    AccountInfoCardComponent,
    LoginCardComponent,
    CartCardComponent,
    OrderListCardComponent,
    CheckoutCardComponent,
    ScrollbarThemeModule,
    AppNavBarComponent,
    AddressCardComponent,
    DeliveryStatusModalComponent,
    AppFooterComponent,
    EditProfileComponent,
    BrandSelectionComponent,
    DownloadModalComponent,
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    IvyCarouselModule,
  ],
})
export class SharedModule {}
