import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ActionSheetController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { CartService } from 'src/app/services/cart.service';
import { TranslateConfigService } from 'src/app/translate-config.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  selectedLanguage: string;

  constructor(
    private router: Router,
    private cartServ: CartService,
    private actionSheetController: ActionSheetController,
    private _storage: Storage,
    private translateConfigService: TranslateConfigService
  ) {
    this.cartCount = this.cartServ.cartItemCount.subscribe((value) => {
      // console.log(value);
      this.cartAmount = value;
    });
  }
  cartCount;
  cartAmount = 0;
  searchData = '';
  ngOnInit() {}
  doSearch() {
    // let navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     formData: JSON.stringify(this.searchData),
    //   },
    //   replaceUrl: true,
    // };

    let navigationExtras: NavigationExtras = {
      queryParams: {
        formData: JSON.stringify(this.searchData),
      },
    };

    this.router.navigate(['/search'], navigationExtras);
    this.searchData = '';
  }
  setSearchData(value) {
    this.searchData = value;
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
  goToCart() {
    this.router.navigate(['/cart']);
  }
  goToProfile() {
    this.router.navigate(['/account']);
  }
  async changeLanguage() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Choose your primary address',
      buttons: [
        {
          text: 'English',
          handler: () => {
            this.updateLanguage('en-US');
          },
        },
        {
          text: 'Bahasa Malaysia',
          handler: () => {
            this.updateLanguage('ms-MY');
          },
        },
        {
          text: 'Chinese',
          handler: () => {
            this.updateLanguage('zh-CN');
          },
        },

        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancelled');
          },
        },
      ],
    });
    await actionSheet.present();
  }

  updateLanguage(lang) {
    // this.userProfile.language;
    this._storage.set('appLanguage', lang).then((res) => {
      this.selectedLanguage = lang;
      this.translateConfigService.setLanguage(this.selectedLanguage);

      location.reload();
    });
  }
}
