import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import * as _ from "lodash";
import { first } from "rxjs/operators";
@Component({
  selector: "app-login-modal",
  templateUrl: "./login-modal.page.html",
  styleUrls: ["./login-modal.page.scss"],
})
export class LoginModalPage implements OnInit, OnDestroy {
  @ViewChild("emailAddress", { static: false }) emailElRef;
  @ViewChild("password", { static: false }) passElRef;

  public loginForm: FormGroup;
  bkBtn;
  user = this.authService.userValue;
  isLoggedIn = this.user && this.user.auth_token;
  returnUrl: string;
  error = "";
  constructor(
    private translate: TranslateService,
    public formBuilder: FormBuilder,

    private modalCtrl: ModalController,
    private authService: AuthService,
    private router: Router,
    private common: CommonService
  ) {
    this.loginForm = formBuilder.group({
      emailAddress: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(8)]],
    });

    this.authService.user.subscribe((res) => {
      if (res !== null) {
        this.user = res;
        this.isLoggedIn = this.user && this.user.auth_token;
        if (this.isLoggedIn) {
          this.closeModal(true);
        }
      }
    });
  }
  ngOnInit() {}

  ionViewWillEnter() {}

  ionViewDidEnter() {}
  onSelect(val) {
    this[val].setFocus();
  }
  get f() {
    return this.loginForm.controls;
  }
  signIn() {
    // if (this.loginForm.valid) {
    //   this.authenticationServ.login(this.loginForm.value);
    //   this.loginForm.controls["password"].reset();
    // }

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    let formData = {
      emailAddress: _.cloneDeep(this.f.emailAddress.value),
      password: _.cloneDeep(this.f.password.value),
      device: {},
    };
    this.common.getDeviceInfo().then((res) => {
      formData.device = _.cloneDeep(res);

      return this.authService
        .login(formData)
        .pipe(first())
        .subscribe({
          next: () => {
            this.closeModal("success");
            // // console.log(this.returnUrl);
            // this.router.navigate([this.returnUrl]);
          },
          error: (error) => {
            // console.log(error);
            this.common.presentErrorToast(error["error"]);
            // this.error = error;
            // this.loading = false;
          },
        });
    });
  }

  ngOnDestroy() {
    // this.bkBtn.unsubscribe();
  }

  isLandscape() {
    if (window.innerHeight > window.innerWidth) {
      return false;
    } else {
      return true;
    }
  }

  closeModal(val?) {
    // console.log("close modal dismiss");
    this.modalCtrl.dismiss(val);
  }
  goToForgotPassword() {
    this.modalCtrl.dismiss().then((res) => {
      this.router.navigate(["/forget-password"]);
    });
  }
  goToRegister() {
    this.modalCtrl.dismiss().then((res) => {
      this.router.navigate(["/register"]);
    });
  }
}
