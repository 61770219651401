import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss'],
})
export class LoginCardComponent implements OnInit, OnDestroy {
  @ViewChild('emailAddress', { static: false }) emailElRef;
  @ViewChild('password', { static: false }) passElRef;

  public loginForm: FormGroup;
  bkBtn;
  user = this.authService.userValue;
  isLoggedIn = this.user && this.user.auth_token;
  returnUrl: string;
  error = '';
  isModalOpened;
  constructor(
    private translate: TranslateService,
    public formBuilder: FormBuilder,
    private platform: Platform,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private router: Router,
    private common: CommonService
  ) {
    this.loginForm = formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    if (this.isLoggedIn) {
      // this.closeModal(true);
    }
  }
  async ngOnInit() {
    this.isModalOpened = await this.modalCtrl.getTop();
  }

  ionViewWillEnter() {}

  ionViewDidEnter() {}
  onSelect(val) {
    // this[val].setFocus();
  }
  get f() {
    return this.loginForm.controls;
  }
  signIn() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    let formData = {
      emailAddress: _.cloneDeep(this.f.emailAddress.value),
      password: _.cloneDeep(this.f.password.value),
      device: {},
    };
    this.common.getDeviceInfo().then((res) => {
      formData.device = _.cloneDeep(res);

      return this.authService
        .login(formData)
        .pipe(first())
        .subscribe({
          next: () => {
            // this.closeModal("success");
            // // console.log(this.returnUrl);
            // this.router.navigate([this.returnUrl]);
          },
          error: (error) => {
            if (error['error']['errorMessage']) {
              if (
                String(error['error']['errorMessage']).indexOf('verify') !== -1
              ) {
                this.common.presentAlert({
                  header: this.translate.instant('Verify!'),
                  message: error['error']['errorMessage'],
                });
              } else {
                this.common.presentToast({
                  message: error['error']['errorMessage'],
                  color: 'danger',
                });
              }
            }
          },
        });
    });
  }

  ngOnDestroy() {}

  isLandscape() {
    if (window.innerHeight > window.innerWidth) {
      return false;
    } else {
      return true;
    }
  }

  closeModal(val?) {
    // console.log("close modal dismiss");
    this.modalCtrl.dismiss(val);
  }
  goToForgotPassword() {
    if (this.isModalOpened) {
      this.closeModal();
    }
    this.router.navigate(['/forget-password']);
  }
  goToRegister() {
    if (this.isModalOpened) {
      this.closeModal();
    }
    this.router.navigate(['/register']);
  }
}
