import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';

@Component({
  selector: 'app-account-info-card',
  templateUrl: './account-info-card.component.html',
  styleUrls: ['./account-info-card.component.scss'],
})
export class AccountInfoCardComponent implements OnInit {
  constructor(
    private common: CommonService,
    private api: ApiService,
    private modalController: ModalController
  ) {}
  userInfo;
  userProfile;
  ngOnInit() {
    this.getProfile();
  }
  getProfile() {
    this.common
      .getUserInfo('user')
      .then((userInfo) => {
        // console.log(userInfo);
        this.userInfo = userInfo;
        return this.getUserProfile();
      })
      .then((res) => {})
      .catch((err) => {
        this.common.presentErrorToast(err);
      });
  }
  getUserProfile() {
    return new Promise((resolve, reject) => {
      this.api.getUserProfile(this.userInfo).subscribe(
        (result) => {
          // console.log(result);
          this.userProfile = result;
          resolve(result);
        },
        (err) => {
          // console.log(err);
          reject(err);
        }
      );
    });
  }
  async editProfile() {
    const dataInput = {
      userInfo: this.userInfo,
      userProfile: this.userProfile,
    };

    const modal = await this.modalController.create({
      component: EditProfileComponent,
      cssClass: 'edit-profile-modal',
      componentProps: {
        dataInput: JSON.stringify(dataInput),
      },
    });
    await modal.present();
    const data = await modal.onDidDismiss();
    if (data?.data?.updatedSuccess) {
      this.ngOnInit();
    }
  }
}
