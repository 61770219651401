import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-delivery-status-modal",
  templateUrl: "./delivery-status-modal.component.html",
  styleUrls: ["./delivery-status-modal.component.scss"],
})
export class DeliveryStatusModalComponent implements OnInit {
  @Input() data: any;
  constructor(private modalController: ModalController) {
    // console.log(this.data);
  }

  ngOnInit() {}

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
